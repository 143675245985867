/*
 * Content:
 *
 * Modules
 * Alert
 * Alert link
 * Alert dismissible
 * Variants
 */


// Modules
// ============================================================================

@use "sass:map";


// Alert
// ============================================================================

.alert {
  position: relative;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  font-weight: $font-weight-normal;
  color: #343a40;
  border: $alert-border-width solid transparent;

  @include border-radius($alert-border-radius);
}


// Alert link
// ============================================================================

.alert-link {
  font-weight: $alert-link-font-weight;
  color: inherit;

  &:hover {
    color: inherit;
  }
}


// Alert dismissible
// ============================================================================

.alert-dismissible {
  padding-right: $alert-dismissible-padding-r;

  .btn-close {
    position: absolute;
    top: $alert-padding-y;
    right: $alert-padding-x;
    z-index: $stretched-link-z-index + 1;
    width: $alert-dismissible-icon-size;
    height: $alert-dismissible-icon-size;
    background-image: escape-svg($alert-dismissible-icon-bg);
  }
}


// Variants
// ============================================================================

@each $theme, $data in $alert-themes {
  .alert-#{$theme} {
    @include alert-variant($data...);
  }
}
