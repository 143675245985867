/*
 * Content:
 *
 * Modules
 * Form text
 * Form control and form select
 * Form floating
 * Form check
 * Validation
 */


// Modules
// ============================================================================

@use "sass:map";


// Form text
// ============================================================================

.form-text {
  padding: 0 calc($input-padding-x + $input-border-width);
  line-height: 1rem; // 16px

  ul {
    @include list-unstyled();
    margin-bottom: 0;
  }
}


// Form control and form select
// ============================================================================

.form-control,
.form-select {
  &:hover {
    box-shadow: $input-hover-box-shadow;

    &:focus {
      box-shadow: $input-hover-box-shadow, $input-focus-box-shadow;
    }

    &:disabled {
      box-shadow: none;
    }
  }


  &:disabled,
  &:disabled::placeholder {
    color: $input-disabled-color;
  }
}


// Form floating
// ============================================================================

.form-floating {
  color: $input-placeholder-color;

  > label {
    font-weight: $input-font-weight;
  }

  > .form-control:focus,
  > .form-select:focus {
    ~ label {
      color: $input-focus-border-color;
    }
  }
}

.form-floating-with-prefix {
  > .form-prefix {
    position: absolute;
    top: calc($form-floating-input-padding-t + $input-border-width);
    left: calc($form-floating-padding-x + $input-border-width);
    display: none;
    color: $input-color;
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown) {
    padding-left: $form-floating-padding-x + 1.25rem;

    ~ .form-prefix {
      display: block;
    }
  }

  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  &:-webkit-autofill {
    padding-left: $form-floating-padding-x + 1.25rem;
  }
}

.form-floating-with-prefix-md {
  > .form-control:focus,
  > .form-control:not(:placeholder-shown) {
    padding-left: $form-floating-padding-x + 2.5rem;
  }

  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  &:-webkit-autofill {
    padding-left: $form-floating-padding-x + 2.5rem;
  }
}


// Form check
// ============================================================================

.form-check-label {
  font-weight: $form-label-font-weight;
}

.form-check-input {
  &:disabled {
    background-color: $light;
  }

  &:checked {
    &:hover:not(:focus):not(:disabled) {
      background-color: map.get($theme-colors-hover, "secondary");
      border-color: map.get($theme-colors-hover, "secondary");
    }

    &:focus {
      background-color: map.get($theme-colors-focus, "secondary");
      border-color: map.get($theme-colors-focus, "secondary");
    }

    &:disabled {
      border-color: $light;
    }
  }

  &[type="checkbox"]:indeterminate {
    &:hover:not(:focus):not(:disabled) {
      background-color: $form-check-input-bg;
      background-image: escape-svg(str-replace($form-check-input-indeterminate-bg-image, "#{$form-check-input-indeterminate-color}", "#{map.get($theme-colors-hover, "secondary")}"));
    }

    &:focus {
      background-color: $form-check-input-bg;
      background-image: escape-svg(str-replace($form-check-input-indeterminate-bg-image, "#{$form-check-input-indeterminate-color}", "#{map.get($theme-colors-focus, "secondary")}"));
    }

    &:disabled {
      background-color: $form-check-input-bg;
      background-image: escape-svg(str-replace($form-check-input-indeterminate-bg-image, "#{$form-check-input-indeterminate-color}", "#{$light}"));
      border-color: $light;
    }
  }
}

.form-check-card {
  display: block;
  padding: spacer(4);
  background-color: $card-bg;
  border: 1px solid $gray-500;
  border-radius: $border-radius;

  .form-check:last-child {
    margin-bottom: 0;
  }
}

.form-check-card-shadow {
  border-color: $gray-200;
  box-shadow: $box-shadow-sm;
}


// Validation
// ============================================================================

@each $state, $data in $form-validation-states {
  .#{$state}-feedback {
    padding: 0 calc($input-padding-x + $input-border-width);
    font-weight: $font-weight-normal;
  }

  .form-control {
    @include form-validation-state-selector($state) {
      @if $enable-validation-icons {
        padding-right: $form-select-padding-x * 2 + $form-feedback-icon-size;
        background-position: right $form-select-padding-x center;
        background-size: $form-feedback-icon-size;
      }
    }
  }

  textarea.form-control {
    @include form-validation-state-selector($state) {
      @if $enable-validation-icons {
        padding-right: $input-padding-x;
        background-image: unset;
      }
    }
  }

  .form-control,
  .form-select {
    &:hover:focus {
      @include form-validation-state-selector($state) {
        box-shadow: $input-hover-box-shadow, map.get($data, "focus-box-shadow");
      }
    }
  }

  .form-floating {
    > .form-control,
    > .form-select {
      @include form-validation-state-selector($state) {
        ~ label {
          color: map.get($data, "color");
        }
      }
    }
  }
}
