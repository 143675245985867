/*
 * Content:
 *
 * Body
 * Main
 */


// Body
// ============================================================================

body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


// Main
// ============================================================================

main {
  flex-grow: 1;
}
