/*
 * Content:
 *
 * Modules
 * Feature list
 * Feature list item
 * Feature list numbered
 */


// Modules
// ============================================================================

@use "sass:map";


// Feature list
// ============================================================================

.feature-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: spacer(6);

  @include list-unstyled();

  @include media-breakpoint-up(lg) {
    flex-flow: row wrap;
    align-items: unset;
    justify-content: center;
  }
}


// Feature list item
// ============================================================================

.feature-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 219px;
  font-weight: $font-weight-semibold;
  color: $gray-800;
  text-align: center;
  gap: spacer(3);
}


// Feature list numbered
// ============================================================================

.feature-list-numbered {
  column-gap: 106px;
  counter-reset: feature;

  .feature-list-item {
    display: flex;
    flex-direction: column;
    max-width: 289px;
    padding: spacer(4) spacer(4) spacer(5);
    font-weight: $font-weight-normal;
    color: $body-color;
    counter-increment: feature;
    border-radius: $border-radius;
    box-shadow: 0 1rem 1.625rem rgb(21 31 56 / 12%);
    gap: 2.5rem; // 40px

    &::before {
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 3rem; // 48px
      height: 3rem; // 48px
      font-size: $h6-font-size;
      font-weight: $font-weight-bold;
      color: $primary;
      content: counter(feature);
      background-color: map.get($theme-colors-light, "secondary");
      border-radius: 50%;
    }

    @include media-breakpoint-up(lg) {
      position: relative;
      width: 25%;

      &:nth-child(3n - 1)::after,
      &:nth-child(3n)::after {
        position: absolute;
        top: 50%;
        right: calc(100% + #{spacer(2)});
        display: block;
        width: 90px;
        height: 19px;
        content: "";
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateY(-50%);
      }

      &:nth-child(3n - 1)::after {
        background-image: escape-svg(url("data:image/svg+xml,<svg width='93' height='22' viewBox='0 0 93 22' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.5 20.5C36.6533 -5.17195 56.346 -4.49244 91.5 20.5' stroke='#35D5CF' stroke-width='3' stroke-linecap='round' stroke-dasharray='6 6'/></svg>"));
      }

      &:nth-child(3n)::after {
        background-image: escape-svg(url("data:image/svg+xml,<svg width='93' height='24' viewBox='0 0 93 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.5 2.43945C36.6533 28.2752 56.346 27.5913 91.5 2.43945' stroke='#35D5CF' stroke-width='3' stroke-linecap='round' stroke-dasharray='6 6'/></svg>"));
      }
    }

    @include media-breakpoint-up(xxl) {
      width: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    align-items: center;
  }
}
