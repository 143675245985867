/*
 * Content:
 *
 * Navbar
 * Navbar toggler
 * Navbar description
 * Navbar collapse custom
 * Navbar nav
 * Navbar themes
 * Navbar expand
 */


// Navbar
// ============================================================================

.navbar {
  box-shadow: $box-shadow;

  ul {
    @include list-unstyled();
  }

  .offcanvas {
    width: 100%;
  }

  .offcanvas-body {
    position: relative;
  }

  .btn {
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
  }
}

// Navbar toggler
// ============================================================================

.navbar-toggler {
  display: inline-flex;
  border: unset;

  &:focus {
    box-shadow: $btn-close-focus-shadow;
  }
}

.navbar-toggler-icon {
  width: $navbar-toggler-font-size;
  height: $navbar-toggler-font-size;
}


// Navbar description
// ============================================================================

.navbar-description {
  display: none;
  margin-bottom: 0;
}

.navbar-description-highlight {
  &::after {
    display: block;
    width: 50%;
    margin-top: spacer(6);
    content: "";
    border-top: 3px solid #35d5cf;
  }
}


// Navbar collapse
// ============================================================================

.navbar-collapse-custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important; // stylelint-disable-line declaration-no-important
  background-color: $white;

  @include transition(left .15s ease);

  ul {
    padding-bottom: spacer(3);
    border-bottom: $border-width solid $border-color;
  }

  .nav-link[data-bs-toggle="collapse"] {
    display: flex;
    align-items: center;
    gap: spacer(2);

    @extend .active; // stylelint-disable-line scss/at-extend-no-missing-placeholder

    &::before {
      display: inline-block;
      content: "";

      @include caret-start($navbar-light-active-color);
    }
  }

  &.collapse {
    &:not(.show) {
      left: 100%;
      display: block;
    }
  }
}


// Navbar nav
// ============================================================================

.navbar-nav {
  .nav-link {
    padding: $navbar-nav-link-padding-y $navbar-nav-link-padding-x;
    font-size: $navbar-nav-link-font-size;
    line-height: $navbar-nav-link-line-height;

    img {
      display: none;
    }

    &.active {
      font-weight: $font-weight-medium;
    }

    &.dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &::after {
        @include caret-down();
      }

      &.show {
        @extend .active; // stylelint-disable-line scss/at-extend-no-missing-placeholder

        &::after {
          @include caret-up();
        }
      }
    }
  }

  .dropdown-menu {
    min-width: 100%;
    padding: 0;
    margin-top: 0;
    border: unset;
    border-radius: unset;
  }

  .nav-item {
    border-bottom: $border-width solid $border-color;
  }

  // Second level .nav-item
  .nav-item .nav-item {
    border-bottom: unset;

    .navbar-description {
      margin-top: spacer(1);
      font-size: $font-size-sm;
    }

    .nav-link {
      padding-top: spacer(2);
      padding-bottom: spacer(2);
    }
  }

  // Third level .nav-item
  .nav-item .nav-item .nav-item {
    .navbar-description {
      font-size: $font-size-xs;
    }

    .nav-link {
      padding-top: .375rem; // 6px
      padding-bottom: .375rem; // 6px
    }
  }
}


// Navbar themes
// ============================================================================

.navbar-light {
  .navbar-description {
    color: $gray-800;
  }

  .navbar-nav {
    .nav-link {
      color: $gray-700;

      &.dropdown-toggle {
        &:hover::after,
        &:focus::after {
          @include caret-down($navbar-light-hover-color);
        }

        &.active::after {
          @include caret-down($navbar-light-active-color);
        }

        &.show::after {
          @include caret-up($navbar-light-active-color);
        }
      }
    }
  }
}


// Navbar expand
// ============================================================================

.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-up($next) {
        position: relative;

        .btn {
          padding-right: calc(spacer(5) - $btn-border-width);
          padding-left: calc(spacer(5) - $btn-border-width);
          font-size: $navbar-nav-link-lg-font-size;
          line-height: $navbar-nav-link-lg-line-height;
        }

        .offcanvas-body {
          position: unset;
        }

        .navbar-description {
          display: block;
        }

        .navbar-nav {
          align-items: center;
          gap: spacer(4);

          .nav-link {
            padding: $navbar-nav-link-lg-padding-y $navbar-nav-link-lg-padding-x;
            font-size: $navbar-nav-link-lg-font-size;
            font-weight: $font-weight-semibold;
            line-height: $navbar-nav-link-lg-line-height;

            img {
              display: inline-block;
              flex-shrink: 0;
            }

            &.active {
              font-weight: $font-weight-semibold;
            }
          }

          .dropdown-menu {
            padding: 2.5rem; // 40px
            box-shadow: $box-shadow;
          }

          .nav-item {
            border-bottom: unset;
          }

          // Second level .nav-item
          .nav-item .nav-item {
            .nav-link {
              display: flex;
              align-items: center;
              gap: spacer(2);
              padding: 0;
              line-height: $line-height-base;

              &::after {
                display: inline-block;
                flex-shrink: 0;
                content: "";

                @include caret-end();
              }

              &[data-bs-toggle="collapse"] {
                pointer-events: none;

                &::after {
                  display: none;
                }
              }
            }
          }

          // Third level .nav-item
          .nav-item .nav-item .nav-item {
            margin-top: spacer(5);
          }
        }

        .navbar-collapse-custom {
          position: unset;

          ul {
            padding-bottom: 0;
            border-bottom: unset;
          }
        }

        &.navbar-light {
          .navbar-nav {
            .nav-link {
              color: $navbar-light-color;

              &:hover,
              &:focus {
                color: $navbar-light-hover-color;
              }

              &.active {
                color: $navbar-light-active-color;
              }
            }

            // Second level
            .nav-item .nav-item {
              .nav-link {
                color: $primary;

                &:hover,
                &:focus {
                  color: $navbar-light-hover-color;

                  &::after {
                    @include caret-end($navbar-light-hover-color);
                  }
                }
              }
            }

            // Third level
            .nav-item .nav-item .nav-item {
              .nav-link {
                color: $navbar-light-color;

                &:hover,
                &:focus {
                  color: $navbar-light-hover-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
