/*
 * Content:
 *
 * Modules
 * Bootstrap mixins
 * Backgrounds
 */


// Modules
// ============================================================================

@use "sass:map";


// Bootstrap mixins
// ============================================================================

@import "~bootstrap/scss/mixins";

@mixin alert-variant(
  $background,
  $border,
  $icon: null,
  $btn-close-color: $border,
) {
  @include gradient-bg($background);
  border-color: $border;

  @if ($icon) {
    padding-left: $alert-padding-x * 2 + $alert-icon-size;

    &::after {
      position: absolute;
      top: 50%;
      left: $alert-padding-y;
      width: $alert-icon-size;
      height: $alert-icon-size;
      content: "";
      background-image: escape-svg($icon);
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);
    }
  }

  .btn-close {
    background-image: escape-svg(str-replace($alert-dismissible-icon-bg, "#{$alert-dismissible-icon-color}", "#{$btn-close-color}"));

    &:focus {
      box-shadow: 0 0 0 $btn-focus-width $btn-close-color;
    }
  }
}

@mixin button-variant(
  $background,
  $color: color-contrast($background),
  $hover-background: shade-color($background, $btn-hover-shade-amount),
  $focus-background: tint-color($background, $btn-focus-tint-amount),
  $active-background: shade-color($background, $btn-active-shade-amount),
) {
  color: $color;
  border-color: $background;

  @include gradient-bg($background);
  @include box-shadow($btn-box-shadow);

  &:hover {
    color: $color;
    border-color: $hover-background;

    @include gradient-bg($hover-background);
  }

  .btn-check:focus + &,
  &:focus {
    color: $color;
    border-color: $white;

    @include gradient-bg($focus-background);

    @if $enable-shadows {
      @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width $focus-background);
    } @else {
      box-shadow: 0 0 0 $btn-focus-width $focus-background;
    }
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  .show > &.dropdown-toggle {
    background-color: $active-background;
    background-image: if($enable-gradients, none, null);
    border-color: $active-background;
  }

  .btn-check:active:focus + &,
  &:active:focus {
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $gray-500;
    background-color: $light;
    background-image: if($enable-gradients, none, null);
    border-color: $light;
    opacity: 1;
  }
}

@mixin button-outline-variant(
  $color,
  $hover-color: shade-color($color, $btn-hover-shade-amount),
  $focus-background: tint-color($color, $btn-focus-tint-amount + 20%),
  $focus-shadow-color: tint-color($color, $btn-focus-tint-amount),
  $active-background: shade-color($color, $btn-active-shade-amount),
) {
  color: $color;
  border-color: $color;

  &:hover {
    color: $hover-color;
    border-color: $hover-color;
  }

  .btn-check:focus + &,
  &:focus {
    color: $color;
    background-color: $focus-background;
    border-color: $white;

    @if $enable-shadows {
      @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width $focus-shadow-color);
    } @else {
      box-shadow: 0 0 0 $btn-focus-width $focus-shadow-color;
    }
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: color-contrast($active-background);
    background-color: $active-background;
    border-color: $active-background;
  }

  .btn-check:active:focus + &,
  &:active:focus {
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $color;
    border-color: $color;
  }
}

@mixin button-size($padding-y, $padding-x, $font-size, $border-radius, $icon-size) {
  min-height: calc($padding-y * 2 + $btn-border-width * 2 + $font-size * $btn-line-height);
  padding: $padding-y $padding-x;

  @include font-size($font-size);
  @include border-radius($border-radius, 0);

  .btn-icon {
    width: $icon-size;
    height: $icon-size;
    font-size: $icon-size;
  }
}

@mixin caret-down($color: $caret-color) {
  width: $caret-width;
  height: $caret-width;
  background-image: escape-svg(str-replace($caret-down-bg, "#{$caret-color}", "#{$color}"));
  background-size: contain;
}

@mixin caret-up($color: $caret-color) {
  width: $caret-width;
  height: $caret-width;
  background-image: escape-svg(str-replace($caret-up-bg, "#{$caret-color}", "#{$color}"));
  background-size: contain;
}

@mixin caret-end($color: $caret-color) {
  width: $caret-width;
  height: $caret-width;
  background-image: escape-svg(str-replace($caret-end-bg, "#{$caret-color}", "#{$color}"));
  background-size: contain;
}

@mixin caret-start($color: $caret-color) {
  width: $caret-width;
  height: $caret-width;
  background-image: escape-svg(str-replace($caret-start-bg, "#{$caret-color}", "#{$color}"));
  background-size: contain;
}


// Backgrounds
// ============================================================================

@mixin split-background($color-a, $color-b) {
  background: linear-gradient(to right, $color-a 0%, $color-a 50%, $color-b 50%, $color-b 100%);
}
