@use "sass:map";

/*
 * Simulation container: target to the section where simulation will be rendered. Each iframe for simulation should be wrapped by a simulation container.

 * Variables: each simulation has an ideal height in order to avoid scroll for desktop and mobile sizes; so, since we are using `iframes` to render simulations, we need to explicitly set up these values.

 Variables are a centralized way to keep track of these values and make easily interchangeable. Each variable contains a map with the ideal `desktop` and `mobile` height values.

 * Simulation classes: since each simulation `iframe` should use its respectively variables for `height`; simulation classes were created in order to target the specific type of simulation and its dimensions.
*/

// Variables
// ============================================================================

$apv-simulation-heights: (
  "desktop": 700px,
  "mobile": 1200px
);

$mutual-funds-simulation-heights: (
  "desktop": 620px,
  "mobile": 700px
);

// Simulation container
// ============================================================================

.simulation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  iframe {
    width: 100%;
  }

  // Simulation classes
  // ============================================================================
  .apv {
    height:
      map.get(
        $map: $apv-simulation-heights, $key: "mobile"
      );

    @include media-breakpoint-up(sm) {
      height: map.get($map: $apv-simulation-heights, $key: "desktop");
    }
  }

  .mutual-funds {
    height: map.get($map: $mutual-funds-simulation-heights, $key: "mobile");

    @include media-breakpoint-up(sm) {
      height: map.get($map: $mutual-funds-simulation-heights, $key: "desktop");
    }
  }
}
