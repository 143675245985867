/*
 * Content:
 *
 * Modules
 * Color system
 * Options
 * Spacing
 * Body
 * Utilities maps
 * Grid containers
 * Grid columns
 * Components
 * Typography
 * Buttons
 * Forms
 * Navbar
 * Cards
 * Tooltips
 * Alerts
 * Spinner
 * Close
 * Offcanvas
 * Stepper
 * Bootstrap variables
 * Choices variables
 */


// Modules
// ============================================================================

@use "sass:map";


// Color system
// ============================================================================

// Grays
$white: #fff;
$gray-100: #f5f7fa;
$gray-200: #e9ecef;
$gray-300: #dfe3e6;
$gray-400: #ced4da;
$gray-500: #afbacc;
$gray-600: #7f90af;
$gray-700: #58606e;
$gray-800: #434a54;
$gray-900: #333840;
$black: #000;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

$grays-rgb: map-loop($grays, to-rgb, "$value");

// Colors
$blue: #0c4497;
$blue-dark: #042164;
$blue-light: #1976d2;
$red: #d9534f;
$yellow: #ee9800;
$green: #5cb85c;
$teal: #00b0b9;

$colors: (
  "blue": $blue,
  "blue-dark": $blue-dark,
  "blue-light": $blue-light,
  "red": $red,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "white": $white,
  "gray": $gray-700,
  "gray-dark": $gray-900
);

// Themes
$primary: $blue;
$secondary: $teal;
$tertiary: $blue-dark;
$success: $green;
$info: #1c7cd5;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-900;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);

$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

$theme-colors-hover: (
  "primary": #062450,
  "secondary": #00676d,
);

$theme-colors-focus: (
  "primary": #5870ae,
  "secondary": #80cbcf,
);

$theme-colors-active: (
  "primary": #072e69,
  "secondary": #008087,
);

$theme-colors-light: (
  "primary": #dbe3ef,
  "secondary": #d9f3f5,
  "success": #e8f6ee,
  "info": #ddebf9,
  "warning": #fdf0d9,
  "danger": #f9e5e5
);


// Options
// ============================================================================

$enable-rfs: false;
$enable-negative-margins: true;


// Spacing
// ============================================================================

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25, // 4px
  2: $spacer * .5, // 8px
  3: $spacer * .75, // 12px
  4: $spacer, // 16px
  5: $spacer * 1.5, // 24px
  6: $spacer * 2, // 32px
  7: $spacer * 3, // 48px
  8: $spacer * 3.5, // 56px
  9: $spacer * 4.25, // 68px
  10: $spacer * 7.25 // 116px
);


// Body
// ============================================================================

$body-color: $gray-700;
$body-bg: $white;


// Utilities maps
// ============================================================================

$utilities-colors: map.merge(
  map.merge(
    $theme-colors-rgb,
    map-add-key-prefix($grays-rgb, gray-)
  ),
  (
    "black": to-rgb($black),
    "white": to-rgb($white),
    "body": to-rgb($body-bg)
  )
);


// Grid containers
// ============================================================================

$container-max-widths: (
  lg: 960px,
  xl: 1140px,
  xxl: 1208px,
);


// Grid columns
// ============================================================================

$grid-gutter-width: 2rem;


// Components
// ============================================================================

$border-color: $gray-300;

$border-radius: .5rem; // 8px
$border-radius-sm: .25rem; // 4px
$border-radius-lg: 1rem; // 16px

$box-shadow: 0 .375rem .875rem rgb(18 25 84 / 7%);
$box-shadow-sm: 0 .25rem .25rem rgb(0 0 0 / 5%);
$box-shadow-lg: 0 .25rem .25rem rgb(0 0 0 / 15%);

$component-active-color: $white;
$component-active-bg: $secondary;

$caret-width: .875rem; // 14px
$caret-vertical-align: middle;
$caret-spacing: spacer(2);
$caret-color: $tertiary;
$caret-down-bg: url("data:image/svg+xml,<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6.53607 10.4309L1.222 5.1168C0.965712 4.8605 0.965712 4.44499 1.222 4.18872L1.84181 3.56892C2.09766 3.31307 2.51233 3.31257 2.76879 3.56783L7.00012 7.77934L11.2314 3.56783C11.4879 3.31257 11.9026 3.31307 12.1584 3.56892L12.7782 4.18872C13.0345 4.44502 13.0345 4.86053 12.7782 5.1168L7.46417 10.4309C7.20788 10.6872 6.79236 10.6872 6.53607 10.4309Z' fill='#{$caret-color}' /></svg>");
$caret-up-bg: url("data:image/svg+xml,<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.46393 3.56911L12.778 8.8832C13.0343 9.1395 13.0343 9.55501 12.778 9.81128L12.1582 10.4311C11.9023 10.6869 11.4877 10.6874 11.2312 10.4322L6.99988 6.22066L2.76857 10.4322C2.51211 10.6874 2.09745 10.6869 1.84159 10.4311L1.22179 9.81128C0.965496 9.55498 0.965496 9.13947 1.22179 8.8832L6.53583 3.56911C6.79212 3.31284 7.20764 3.31284 7.46393 3.56911Z' fill='#{$caret-color}' /></svg>");
$caret-end-bg: url("data:image/svg+xml,<svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10.431 7.96393L5.11692 13.278C4.86063 13.5343 4.44511 13.5343 4.18885 13.278L3.56904 12.6582C3.31319 12.4023 3.3127 11.9877 3.56795 11.7312L7.77946 7.49988L3.56795 3.26857C3.3127 3.01211 3.31319 2.59745 3.56904 2.34159L4.18884 1.72179C4.44514 1.4655 4.86065 1.4655 5.11692 1.72179L10.431 7.03583C10.6873 7.29212 10.6873 7.70764 10.431 7.96393Z' fill='#{$caret-color}' /></svg>");
$caret-start-bg: url("data:image/svg+xml,<svg width='1000' height='1000' viewBox='0 0 1000 1000' xmlns='http://www.w3.org/2000/svg'><rect x='0' y='0' width='100%' height='100%' fill='none'/><g transform='matrix(0 76.1342 -76.1342 0 499.9999 500.0001)'><path transform=' translate(-7.0001, -6.9999)' d='M 6.53607 10.4309 L 1.222 5.1168 C 0.965712 4.8605 0.965712 4.44499 1.222 4.18872 L 1.84181 3.56892 C 2.09766 3.31307 2.51233 3.31257 2.76879 3.56783 L 7.00012 7.77934 L 11.2314 3.56783 C 11.4879 3.31257 11.9026 3.31307 12.1584 3.56892 L 12.7782 4.18872 C 13.0345 4.44502 13.0345 4.86053 12.7782 5.1168 L 7.46417 10.4309 C 7.20788 10.6872 6.79236 10.6872 6.53607 10.4309 Z' fill='#{$caret-color}' /></g></svg>");


// Typography
// ============================================================================

$font-family-sans-serif: "Poppins", system-ui, -apple-system, "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-base: 1rem; // 16px
$font-size-lg: $font-size-base * 1.125; // 18px
$font-size-sm: $font-size-base * .875; // 14px
$font-size-xs: $font-size-base * .75; // 12px

$line-height-base: 1.5;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-weight-base: $font-weight-normal;

// Headings
$h1-font-size: $font-size-base * 4.5; // 72px
$h2-font-size: $font-size-base * 4; // 64px
$h3-font-size: $font-size-base * 3; // 48px
$h4-font-size: $font-size-base * 2; // 32px
$h5-font-size: $font-size-base * 1.5; // 24px
$h6-font-size: $font-size-base * 1.25; // 20px

$headings-color: $gray-900;
$headings-font-weight: $font-weight-bold;
$headings-line-height: $line-height-base;

// Displays
$display-font-sizes: (); // Remove displays

// Lead paragraph
$lead-font-size: $font-size-base * 1.25;

// Small text
$small-font-size: 80%;

// Muted
$text-muted: $gray-500;

// Utilities maps
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  lg: $font-size-lg,
  base: $font-size-base,
  sm: $font-size-sm,
  xs: $font-size-xs
);


// Buttons
// ============================================================================

$btn-border-width: 2px;
$btn-padding-y: calc(spacer(2) - $btn-border-width);
$btn-padding-x: calc(spacer(6) - $btn-border-width);
$btn-font-size: $font-size-base;
$btn-line-height: $line-height-base;
$btn-font-weight: $font-weight-bold;
$btn-icon-size: 1.25rem; // 20px

// Focus
$btn-focus-width: 2px;
$btn-focus-box-shadow: 0 0 0 $btn-focus-width map.get($theme-colors-focus, "primary");

// Disabled
$btn-disabled-opacity: .5;

// Shade and tint amounts
$btn-hover-shade-amount: 20%;
$btn-focus-tint-amount: 30%;
$btn-active-shade-amount: 15%;

// Btn link
$btn-link-color: $primary;
$btn-link-hover-color: map.get($theme-colors-hover, "primary");
$btn-link-focus-color: map.get($theme-colors-focus, "primary");
$btn-link-active-color: map.get($theme-colors-focus, "primary");
$btn-link-disabled-color: $btn-link-color;


// Forms
// ============================================================================

// Form text
$form-text-font-size: $font-size-xs;
$form-text-font-weight: $font-weight-normal;
$form-text-color: $gray-700;

// Form label
$form-label-font-weight: $font-weight-normal;

// Input
$input-padding-y: spacer(4);
$input-padding-x: spacer(4);
$input-color: $gray-700;
$input-font-weight: $font-weight-medium;
$input-border-color: $gray-500;
$input-border-radius: $border-radius-sm;

$input-focus-border-color: $primary;
$input-focus-width: 1px;
$input-focus-box-shadow: 0 0 0 $input-focus-width $input-focus-border-color;

$input-hover-box-shadow: 0 0 2.5rem rgb(18 25 84 / 10%);

$input-disabled-color: $gray-500;
$input-disabled-bg: $gray-200;
$input-disabled-border-color: $input-disabled-bg;

$input-placeholder-color: $gray-500;

$input-transition: none;

// Check
$form-check-input-width: 1.25rem;
$form-check-padding-start: $form-check-input-width + spacer(4);

$form-check-input-border-width: 2px;
$form-check-input-border: $form-check-input-border-width solid $gray-500;
$form-check-input-border-radius: $form-check-input-border-width;

$form-check-input-focus-border: $form-check-input-border-width solid $gray-400;
$form-check-input-focus-box-shadow: 0 0 0 2px $body-bg, 0 0 0 4px $secondary;

$form-check-input-checked-color: $component-active-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M15.2449 5.24418C15.3218 5.16459 15.4138 5.10111 15.5154 5.05743C15.6171 5.01376 15.7264 4.99077 15.8371 4.98981C15.9477 4.98885 16.0575 5.00993 16.1599 5.05183C16.2623 5.09373 16.3554 5.15561 16.4336 5.23385C16.5118 5.3121 16.5737 5.40514 16.6156 5.50756C16.6575 5.60997 16.6786 5.7197 16.6776 5.83035C16.6767 5.941 16.6537 6.05035 16.61 6.15202C16.5663 6.25369 16.5029 6.34564 16.4233 6.42252L8.92327 13.9225C8.76699 14.0787 8.55507 14.1665 8.3341 14.1665C8.11313 14.1665 7.90121 14.0787 7.74493 13.9225L3.57743 9.75502C3.42563 9.59785 3.34164 9.38735 3.34354 9.16885C3.34544 8.95035 3.43308 8.74134 3.58758 8.58683C3.74209 8.43233 3.9511 8.34469 4.1696 8.34279C4.3881 8.34089 4.5986 8.42489 4.75577 8.57668L8.3341 12.155L15.2449 5.24418Z' fill='#{$form-check-input-checked-color}'/></svg>");

$form-check-input-indeterminate-color: $component-active-bg;
$form-check-input-indeterminate-bg-color: $component-active-color;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4.16659 10.8333H15.8333C16.3891 10.8333 16.6666 10.5567 16.6666 9.99999C16.6666 9.44332 16.3891 9.16666 15.8333 9.16666H4.16659C3.61075 9.16666 3.33325 9.44332 3.33325 9.99999C3.33325 10.5567 3.61075 10.8333 4.16659 10.8333Z' fill='#{$form-check-input-indeterminate-color}'/></svg>");

$form-check-input-disabled-opacity: 1;

// Select
$form-select-padding-x: $input-padding-x;

$form-select-indicator-size: 1.5rem; // 24px
$form-select-bg-size: $form-select-indicator-size;
$form-select-indicator-padding: $form-select-padding-x * 2 + $form-select-indicator-size;
$form-select-indicator-color: $input-placeholder-color;
$form-select-indicator: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M16.59 8.59009L12 13.1701L7.41 8.59009L6 10.0001L12 16.0001L18 10.0001L16.59 8.59009Z' fill='#{$form-select-indicator-color}'/></svg>");

$form-select-focus-box-shadow: $input-focus-box-shadow;

$form-select-disabled-color: $input-disabled-color; // This value can't be `null` for overriding choices styles
$form-select-disabled-bg: $input-disabled-bg;

$form-select-feedback-icon-size: 1.875rem; // 30px
$form-select-feedback-icon-position: center right ($form-select-padding-x + $form-select-indicator-size);
$form-select-feedback-icon-padding-end: $form-select-feedback-icon-size + $form-select-indicator-padding;

// Form floating
$form-floating-line-height: $line-height-base;
$form-floating-padding-y: $input-padding-y;
$form-floating-padding-x: $input-padding-x;

$form-floating-input-padding-t: spacer(5);
$form-floating-input-padding-b: spacer(2);

$form-floating-label-opacity: 1;
$form-floating-label-transform: scale(.75) translateY(-.5rem) translateX(.25rem);
$form-floating-transition: transform .1s ease-in-out;

// Form validation
$form-feedback-icon-size: $form-select-feedback-icon-size;

$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;
$form-feedback-warned-color: $warning;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M25 15C25 20.5228 20.5228 25 15 25C9.47715 25 5 20.5228 5 15C5 9.47715 9.47715 5 15 5C20.5228 5 25 9.47715 25 15ZM19.0303 11.9697C19.3232 12.2626 19.3232 12.7374 19.0303 13.0303L14.0303 18.0303C13.7374 18.3232 13.2626 18.3232 12.9697 18.0303L10.9697 16.0303C10.6768 15.7374 10.6768 15.2626 10.9697 14.9697C11.2626 14.6768 11.7374 14.6768 12.0303 14.9697L13.5 16.4393L15.7348 14.2045L17.9697 11.9697C18.2626 11.6768 18.7374 11.6768 19.0303 11.9697Z' fill='#{$form-feedback-icon-valid-color}'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M25 15C25 20.5228 20.5228 25 15 25C9.47715 25 5 20.5228 5 15C5 9.47715 9.47715 5 15 5C20.5228 5 25 9.47715 25 15ZM11.9696 11.9696C12.2625 11.6768 12.7374 11.6768 13.0303 11.9696L15 13.9393L16.9696 11.9697C17.2625 11.6768 17.7374 11.6768 18.0303 11.9697C18.3232 12.2626 18.3232 12.7374 18.0303 13.0303L16.0606 15L18.0303 16.9696C18.3232 17.2625 18.3232 17.7374 18.0303 18.0303C17.7374 18.3232 17.2625 18.3232 16.9696 18.0303L15 16.0607L13.0303 18.0303C12.7374 18.3232 12.2625 18.3232 11.9696 18.0303C11.6768 17.7374 11.6768 17.2625 11.9696 16.9697L13.9393 15L11.9696 13.0303C11.6767 12.7374 11.6767 12.2625 11.9696 11.9696Z' fill='#{$form-feedback-icon-invalid-color}'/></svg>");
$form-feedback-icon-warned-color: $form-feedback-warned-color;
$form-feedback-icon-warned: url("data:image/svg+xml,<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M8.31171 13.7615C11.2301 8.58716 12.6893 6 15 6C17.3107 6 18.7699 8.58716 21.6883 13.7615L22.0519 14.4063C24.4771 18.7061 25.6897 20.856 24.5937 22.428C23.4978 24 20.7864 24 15.3637 24H14.6363C9.21356 24 6.50217 24 5.40626 22.428C4.31034 20.856 5.52291 18.7061 7.94805 14.4063L8.31171 13.7615ZM15 10.25C15.4142 10.25 15.75 10.5858 15.75 11V16C15.75 16.4142 15.4142 16.75 15 16.75C14.5858 16.75 14.25 16.4142 14.25 16V11C14.25 10.5858 14.5858 10.25 15 10.25ZM15 20C15.5523 20 16 19.5523 16 19C16 18.4477 15.5523 18 15 18C14.4477 18 14 18.4477 14 19C14 19.5523 14.4477 20 15 20Z' fill='#{$form-feedback-icon-warned-color}'/></svg>");

$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid,
    "focus-box-shadow": 0 0 0 $input-focus-width $form-feedback-valid-color
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid,
    "focus-box-shadow": 0 0 0 $input-focus-width $form-feedback-invalid-color
  ),
  "warned": (
    "color": $form-feedback-warned-color,
    "icon": $form-feedback-icon-warned,
    "focus-box-shadow": 0 0 0 $input-focus-width $form-feedback-warned-color
  )
);


// Navbar
// ============================================================================

$navbar-padding-y: spacer(4);

$navbar-nav-link-padding-y: spacer(4);
$navbar-nav-link-padding-x: spacer(5);
$navbar-nav-link-font-size: $font-size-lg; // 18px
$navbar-nav-link-line-height: 1.7778; // 32px

$navbar-nav-link-lg-padding-y: spacer(2);
$navbar-nav-link-lg-padding-x: spacer(3);
$navbar-nav-link-lg-font-size: $font-size-sm; // 14px
$navbar-nav-link-lg-line-height: 1.7143; // 24px
$navbar-nav-link-lg-height: ($font-size-base * $line-height-base) + ($navbar-nav-link-lg-padding-y * 2);
$navbar-lg-height: ($navbar-padding-y * 2) + $navbar-nav-link-lg-height;

$navbar-brand-height: 2.5rem;
$navbar-brand-padding-y: 0;

$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;
$navbar-toggler-font-size: 2.125rem;

$navbar-light-color: $tertiary;
$navbar-light-hover-color: $blue-light;
$navbar-light-active-color: $blue-light;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3.03613 8.37547C3.03613 7.77357 3.52406 7.28564 4.12595 7.28564H30.2817C30.8836 7.28564 31.3715 7.77357 31.3715 8.37547C31.3715 8.97736 30.8836 9.46529 30.2817 9.46529H4.12595C3.52406 9.46529 3.03613 8.97736 3.03613 8.37547ZM3.03613 17.094C3.03613 16.4921 3.52406 16.0042 4.12595 16.0042H30.2817C30.8836 16.0042 31.3715 16.4921 31.3715 17.094C31.3715 17.6959 30.8836 18.1839 30.2817 18.1839H4.12595C3.52406 18.1839 3.03613 17.6959 3.03613 17.094ZM8.84851 25.8126C8.84851 25.2107 9.33644 24.7228 9.93833 24.7228H30.2817C30.8836 24.7228 31.3715 25.2107 31.3715 25.8126C31.3715 26.4145 30.8836 26.9024 30.2817 26.9024H9.93833C9.33644 26.9024 8.84851 26.4145 8.84851 25.8126Z' fill='#{$tertiary}'/></svg>");


// Cards
// ============================================================================

$card-border-color: $border-color;
$card-border-radius: $border-radius-lg;
$card-box-shadow: $box-shadow-lg;


// Tooltips
// ============================================================================

$tooltip-font-size: $font-size-base;
$tooltip-max-width: 235px;
$tooltip-color: #343a40;
$tooltip-bg: $white;
$tooltip-opacity: 1;
$tooltip-padding-y: spacer(2);
$tooltip-padding-x: spacer(2);

// Arrow
$tooltip-arrow-width: .5rem; // 8px
$tooltip-arrow-height: .25rem; // 4px


// Alerts
// ============================================================================

$alert-padding-x: spacer(4);

// Icon
$alert-icon-size: 2rem; // 32px

// Themes
$alert-themes: (
  "success": (
    "background": map.get($theme-colors-light, "success"),
    "border": $success,
    "icon": url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$success}'><path d='M0 0h24v24H0V0zm0 0h24v24H0V0z' fill='none'/><path d='M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>")
  ),
  "info": (
    "background": map.get($theme-colors-light, "info"),
    "border": $info,
    "icon": url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$info}'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>")
  ),
  "warning": (
    "background": map.get($theme-colors-light, "warning"),
    "border": $warning,
    "icon": url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$warning}'><g><rect fill='none' height='24' width='24'/></g><g><g><g><path d='M12,5.99L19.53,19H4.47L12,5.99 M12,2L1,21h22L12,2L12,2z'/><polygon points='13,16 11,16 11,18 13,18'/><polygon points='13,10 11,10 11,15 13,15'/></g></g></g></svg>")
  ),
  "danger": (
    "background": map.get($theme-colors-light, "danger"),
    "border": $danger,
    "icon": url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$danger}'><g><rect fill='none' height='24' width='24'/></g><g><g><g><path d='M12,5.99L19.53,19H4.47L12,5.99 M12,2L1,21h22L12,2L12,2z'/><polygon points='13,16 11,16 11,18 13,18'/><polygon points='13,10 11,10 11,15 13,15'/></g></g></g></svg>")
  ),
  "light": (
    "background": $white,
    "border": #ebeff5,
    "icon": url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$black}'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>"),
    "btn-close-color": $black
  )
);

// Dismissible
$alert-dismissible-icon-size: 1.5rem; // 24px
$alert-dismissible-icon-color: $black;
$alert-dismissible-icon-bg: url("data:image/svg+xml,<svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M17.75 7.6825L16.6925 6.625L12.5 10.8175L8.3075 6.625L7.25 7.6825L11.4425 11.875L7.25 16.0675L8.3075 17.125L12.5 12.9325L16.6925 17.125L17.75 16.0675L13.5575 11.875L17.75 7.6825Z' fill='#{$alert-dismissible-icon-color}'/></svg>");
$alert-dismissible-padding-r: $alert-padding-x * 2 + $alert-dismissible-icon-size;


// Spinner
// ============================================================================

$spinner-border-width: 2px;


// Close
// ============================================================================

$btn-close-width: 1.75rem;
$btn-close-padding-y: 0;
$btn-close-padding-x: 0;
$btn-close-focus-shadow: 0 0 0 $btn-focus-width $tertiary;
$btn-close-opacity: 1;
$btn-close-hover-opacity: 1;
$btn-close-color: $tertiary;
$btn-close-bg: url("data:image/svg+xml,<svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M22.1673 7.4785L20.5223 5.8335L14.0007 12.3552L7.47898 5.8335L5.83398 7.4785L12.3557 14.0002L5.83398 20.5218L7.47898 22.1668L14.0007 15.6452L20.5223 22.1668L22.1673 20.5218L15.6457 14.0002L22.1673 7.4785Z' fill='#{$btn-close-color}'/></svg>");


// Offcanvas
// ============================================================================

$offcanvas-border-width: 0;


// Stepper
// ============================================================================

$stepper-item-color: $gray-400;
$stepper-item-active-color: $secondary;
$stepper-item-done-color: $stepper-item-color;

$stepper-progress-height: 5px;


// Bootstrap variables
// ============================================================================

@import "~bootstrap/scss/variables";


// Choices variables
// ============================================================================

$choices-guttering: 0;
$choices-border-radius: $form-select-border-radius;
$choices-bg-color: $form-select-bg;
$choices-bg-color-disabled: $form-select-disabled-bg;
$choices-keyline-color: $form-select-border-color;

// Dropdown
$choices-bg-color-dropdown: $gray-100;
$choices-dropdown-border-width: 0;
$choices-dropdown-border-color: $choices-keyline-color;

$choices-dropdown-item-paddiny-y: $form-select-padding-y;
$choices-dropdown-item-paddiny-x: $form-select-padding-x;
$choices-dropdown-item-font-size: $font-size-base;
$choices-dropdown-item-line-height: $line-height-base;
$choices-dropdown-item-color: $dropdown-link-color;

$choices-dropdown-item-active-color: $dropdown-link-active-color;
$choices-dropdown-item-active-bg: $dropdown-link-active-bg;

$choices-dropdown-item-hover-color: $dropdown-link-hover-color;
$choices-dropdown-item-hover-bg: $dropdown-link-hover-bg;

$choices-dropdown-item-disabled-color: $dropdown-link-disabled-color;

$choices-dropdown-group-item-padding-l: $choices-dropdown-item-paddiny-x * 2;

// Multiple item
$choices-border-radius-item: $badge-border-radius;
$choices-multiple-item-padding-y: 0;
$choices-multiple-item-padding-x: spacer(1);
$choices-multiple-item-bg: $gray-100;
$choices-multiple-item-font-size: $font-size-base;
$choices-multiple-item-line-height: $line-height-base;
$choices-multiple-item-color: $secondary;
$choices-multiple-item-border-width: $border-width;
$choices-multiple-item-border-color: $choices-multiple-item-color;
$choices-multiple-item-spacer: spacer(2);

$choices-multiple-item-disabled-bg: $form-select-disabled-bg;
$choices-multiple-item-disabled-color: $choices-multiple-item-color;
$choices-multiple-item-disabled-border-color: $choices-multiple-item-border-color;

// Remove button
$choices-button-dimension: .5rem;
$choices-button-offset: spacer(1);
$choices-button-color: $choices-multiple-item-color;
$choices-button-disabled-color: $choices-multiple-item-disabled-color;
$choices-button-focus-shadow: $btn-close-focus-shadow;

$choices-icon-cross-svg: str-replace($btn-close-bg, #{$btn-close-color}, #{$choices-button-color});
$choices-icon-cross: escape-svg($choices-icon-cross-svg);

// Heading
$choices-heading-padding-y: $choices-dropdown-item-paddiny-y;
$choices-heading-padding-x: $choices-dropdown-item-paddiny-x;
$choices-heading-font-weight: $font-weight-base;
$choices-heading-font-size: $font-size-base;
$choices-heading-line-height: $line-height-base;
$choices-heading-color: $gray-700;
$choices-heading-border-width: $border-width;
$choices-heading-border-color: $border-color;
