/*
 * Content:
 *
 * Stepper progress
 * Stepper list
 */


// Stepper progress
// ============================================================================

.stepper .progress {
  height: $stepper-progress-height;
  border-radius: unset;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.stepper .progress-bar {
  background-color: $stepper-item-active-color;
}


// Stepper list
// ============================================================================

.stepper-list {
  display: flex;
  gap: spacer(4);
  counter-reset: step;

  @include list-unstyled();
}

.stepper-list-item {
  flex: 1;
  padding-top: spacer(1);
  font-weight: $font-weight-bold;
  color: $stepper-item-color;
  text-align: center;

  &::before {
    display: inline;
    content: counter(step) "-";
    counter-increment: step;
  }

  &:not(.active) {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
  }

  &.active {
    color: $stepper-item-active-color;
  }

  &.done {
    color: $stepper-item-done-color;
  }

  @include media-breakpoint-up(lg) {
    border-top: $stepper-progress-height solid $stepper-item-color;

    &.active,
    &.done {
      border-color: $stepper-item-active-color;
    }

    &:not(.active) {
      position: unset;
      width: unset;
      height: unset;
      overflow: unset;
      clip: unset;
    }
  }
}
