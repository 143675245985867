/*
 * Content:
 *
 * Modules
 * Btn
 * Btn variants
 * Btn link
 */


// Modules
// ============================================================================

@use "sass:map";


// Button
// ============================================================================

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  color: $body-color;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;

  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius, $btn-icon-size);
  @include transition($btn-transition);

  &:hover {
    color: $body-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
  }

  .btn-check:focus + &,
  &:focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    pointer-events: none;
    opacity: $btn-disabled-opacity;

    @include box-shadow(none);
  }
}


// Button variants
// ============================================================================

@each $color, $value in $theme-colors {
  $hover-value: map.get($theme-colors-hover, $color);
  $focus-value: map.get($theme-colors-focus, $color);
  $active-value: map.get($theme-colors-active, $color);
  $light-value: map.get($theme-colors-light, $color);

  // Set default values in case any is null
  $hover-value: shade-color($value, $btn-hover-shade-amount) !default;
  $focus-value: tint-color($value, $btn-focus-tint-amount) !default;
  $active-value: shade-color($value, $btn-active-shade-amount) !default;
  $light-value: tint-color($value, $btn-focus-tint-amount + 20%) !default;

  .btn-#{$color} {
    @include button-variant(
      $value,
      $color: $white,
      $hover-background: $hover-value,
      $focus-background: $focus-value,
      $active-background: $active-value
    );
  }

  .btn-outline-#{$color} {
    @include button-outline-variant(
      $value,
      $hover-color: $hover-value,
      $focus-background: $light-value,
      $focus-shadow-color: $focus-value,
      $active-background: $active-value
    );
  }
}


// Button link
// ============================================================================

.btn-link {
  padding: $btn-padding-y spacer(2);
  color: $primary;
  text-decoration: underline;

  &:hover {
    color: map.get($theme-colors-hover, "primary");
    text-decoration: underline;
  }

  &:focus {
    color: map.get($theme-colors-focus, "primary");
  }

  &:active,
  &.active {
    color: map.get($theme-colors-active, "primary");
    background-color: map.get($theme-colors-light, "primary");
  }

  &:active:focus {
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $primary;
  }
}
